@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@500&display=swap");

body {
  font-family: "Ubuntu", sans-serif;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
/* 
general 
-------
*/
body {
  font-size: 16px;
  line-height: 1.6;
  color: #f6f6f6;
  overflow-x: hidden;
}
.navbar {
  background: transparent;
  height: 80px;
  font-size: 1.2rem;
  font-weight: bold;
  position: sticky;
  top: 0;
}
.navbar.active {
  background: #4fa9d2;
}

.container-fluid {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 25px;
}
.ant-layout {
  background: #fff !important;
}

.mainLayout .ant-layout-header {
  background: none;
  padding: 0;
  height: auto;
  line-height: 1.6;
}

p {
  font-size: 16px;
  line-height: 1.6;
}

.bgGray {
  background: #fafafa;
}

/* 
  title holder
  ------------
  */
.titleHolder {
  text-align: center;
  position: relative;
  padding: 0 0 20px;
  margin: 0 0 40px;
}

.titleHolder:after {
  transform: translateX(-50%);
  content: "";
  background: #4fa9d2;
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 3px;
  width: 50px;
}

.titleHolder h2 {
  font-size: 28px;
  margin: 0;
}

/* 
  header
  ------ 
  */

.ant-layout-header {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 999;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
}

.header .logo {
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 400;
}

.header .logo .logoimage {
  font-size: 28px;
  width: 50px;
}
.header .logo a {
  color: #fafafa;
}

.header .ant-anchor-wrapper {
  background: none;
}

.header .ant-anchor {
  display: flex;
}

.header .ant-anchor-ink {
  display: none;
}

.header .ant-anchor-link {
  padding: 0 20px;
  line-height: 1.4;
  position: relative;
}

.header .ant-anchor-link:before,
.header .ant-anchor-link:after {
  content: "";
  background: #fafafa;
  width: 1px;
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 30%;
  opacity: 0;
  transition: all 0.3s linear;
}

.header .ant-anchor-link:after {
  left: auto;
  right: 30%;
}

.header .ant-anchor-link.ant-anchor-link-active:before,
.header .ant-anchor-link:hover:before {
  left: 5%;
  opacity: 1;
}

.header .ant-anchor-link.ant-anchor-link-active:after,
.header .ant-anchor-link:hover:after {
  right: 5%;
  opacity: 1;
}

/* ant menu */
.header .ant-menu {
  border: 0;
  background: none !important;
}

.header .ant-menu li.ant-menu-item {
  position: relative;
  color: #344150;
}

.header .ant-menu li.ant-menu-item:before,
.header .ant-menu li.ant-menu-item:after {
  content: "";
  background: #fafafa;
  width: 1px;
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 30%;
  opacity: 0;
  transition: all 0.3s linear;
}

.header .ant-menu li.ant-menu-item:after {
  left: auto;
  right: 30%;
}

.header .ant-menu li.ant-menu-item,
.header .ant-menu li.ant-menu-item-selected {
  border: 0 !important;
}

.header .ant-menu li.ant-menu-item-selected,
.header .ant-menu li.ant-menu-item:hover {
  color: #4fa9d2;
  background: none;
}

.header .ant-menu li.ant-menu-item-selected:before,
.header .ant-menu li.ant-menu-item:hover:before {
  left: 5%;
  opacity: 1;
}

.header .ant-menu li.ant-menu-item-selected:after,
.header .ant-menu li.ant-menu-item:hover:after {
  right: 5%;
  opacity: 1;
}

/* 
  hero
  ---- 
  */
.heroBlock {
  background: url("assets/images/chipsemi.jpg") no-repeat;
  background-position: 50% 40%;
  background-size: cover;
  height: 100vh;
  width: 100%;
}
.welcome {
  background: url("assets/images/hand.jpg") no-repeat;
  background-position: 50% 35%;
  background-size: cover;
  height: 100vh;
}
.carousel1 {
  background: url("assets/images/dron.jpg") no-repeat;
  background-position: 50% 40%;
  background-size: cover;
  height: 100vh;
}
.carousel2 {
  background: url("assets/images/ai2.jpg") no-repeat;
  background-position: 50% 60%;
  background-size: cover;
  height: 100vh;
}

.heroBlock .container-fluid {
  display: block !important;
}

.heroBlock .ant-carousel,
.heroBlock .ant-carousel div {
  height: 100%;
}

.heroBlock .slick-dots li button {
  background: #fafafa !important;
}

.heroBlock .content {
  max-width: 640px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 70px;
}

.heroBlock h3 {
  font-family: "Ubuntu", sans-serif;
  font-size: 3rem;
  line-height: 1.2;
  margin: 0 0 30px;
  color: #fff;
}

.heroBlock p {
  margin: 0 0 30px;
  font-weight: 500;
  font-size: 1rem;
  color: #fafafa;
}

.heroBlock .btnHolder {
  height: auto !important;
}

.heroBlock .btnHolder button,
.heroBlock .btnHolder .fas {
  margin: 0 10px 0 0;
}

/* 
  block
  ----- 
  */
.block {
  padding: 60px 0;
  border-bottom: 1px solid #d9d9d9;
}

/* 
  about
  ----- 
  */
.aboutBlock .titleHolder h2 {
  font-weight: bold;
}
.aboutBlock .contentHolder {
  text-align: center;
  text-align: justify;
  margin: 0 0 50px;
  /* color: #fff; */
  font-size: 28px;
  font-weight: 500;
}

.aboutBlock .about-content {
  color: #344150;
}

.aboutBlock .content {
  text-align: center;
}
/* 
 Career 
 ------
*/
/* --------------- */
.careerpage {
  background: #4fa9d2;
  height: 50vh;
  width: 100%;
}
.careerpage .content {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.contactpage .content h3,
.softwarepage .content h3,
.embeddedpage .content h3,
.semiconductorpage .content h3,
.careerpage .content h3 {
  font-size: 3rem;
  font-weight: bold;
  color: #fafafa;
}
/* -------------- */
.careerBlock .contentHolder {
  text-align: center;
  margin: 0 0 50px;
  font-size: 25px;
  font-weight: 500;
  text-align: justify;
}
.careerBlock .career-content {
  color: #344150;
}
.careerBlock .titleHolder h2 {
  font-weight: bold;
}
/* 
  contact
  -------
  */
.contactpage {
  background: #4fa9d2;
  height: 50vh;
  width: 100%;
}
.contactpage .content {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.contactBlock .ant-form {
  max-width: 640px;
  margin: auto;
}

.contactBlock .ant-form textarea {
  min-height: 120px;
  height: 120px;
}
.contactBlock .contentHolder {
  text-align: center;
  margin: 0 0 50px;
}
.contactBlock .btnFlip {
  margin-top: 10px;
}
.contactBlock .contentMainCard {
  background-color: #4fa9d2;
}
.contactBlock .contentCard {
  text-align: justify;
  font-weight: 500;
  color: #fff;
}
/* 
  footer
  ------
  */
.ant-layout-footer {
  background: #111 !important;
  color: #fff !important;
  padding: 60px 0 !important;
  text-align: center;
}
.footer .logo {
  font-size: 22px;
  text-transform: uppercase;
  margin: 0 0 20px;
}

.footer .logo .logoimage {
  font-size: 28px;
  width: 50px;
}

.footer .logo a {
  color: #fff;
}

.footer .logo a:hover {
  color: #4fa9d2;
}

.footer .goTop:hover {
  opacity: 0.7;
}

.goTop {
  font-size: 25px;
  color: #4fa9d2;
}
.footer-icon {
  font-size: 1.1rem;
  color: #4fa9d2;
}
.footer-icon:hover {
  color: #fafafa;
}

.mobileVisible {
  display: none;
}

.mobileHidden {
  display: block;
}
.nav-text {
  display: flex;
  justify-content: start;
  padding: 8px 0px 8px 0px;
  list-style: none;
  height: 40px;
}
.nav-text a {
  color: #4fa9d2;
  font-weight: 500;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 4px;
}
.nav-menu-items {
  width: 100%;
}
/* 
  responsive
  ----------
  */
@media only screen and (max-width: 767px) {
  .mobileVisible {
    display: block;
  }

  .mobileHidden {
    display: none;
  }
  .embeddedpage .content {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -12%;
  }
}

/* 
  Semiconductor
  ------------ 
*/
.semiconductorpage .content {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.semiconductorpage {
  background: #4fa9d2;
  height: 50vh;
  width: 100%;
}
.semiconductorBlock .titleHolder h2 {
  margin-top: 10px;
  font-weight: bold;
}
.semiconductor-list,
.semiconductorBlock .semiconductor-content {
  color: #344150;
}
.semiconductorBlock .semicondutor-card {
  max-height: 300px;
}
.semiconductorBlock .semiconductor-row {
  margin-top: 5%;
}

.semiconductorBlock .semiconductor-list {
  margin-top: 5%;
  list-style-type: none;
  font-size: large;
}
.semiconductorBlock .fas {
  color: #4fa9d2;
}
.semiconductorBlock .semiconductor-list .semiconductor-list-element {
  font-size: 1.2rem;
  font-weight: bold;
  padding: 5px;
}

/* 
 Embeddd
 ------- 
*/
.embeddedpage {
  background: #4fa9d2;
  height: 50vh;
  width: 100%;
}
.embeddedpage .content {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.embeddedBlock .titleHolder h2 {
  margin-top: 10px;
  font-weight: bold;
}
.embedded-list,
.embeddedBlock .embedded-content {
  color: #344150;
}
.embeddedBlock .embedded-card {
  max-height: 300px;
}
.embeddedBlock .embedded-row {
  margin-top: 5%;
}
.embeddedBlock .fas {
  color: #4fa9d2;
}

.embeddedBlock .embedded-list {
  margin-top: 10%;
  list-style-type: none;
  font-size: large;
}
.embeddedBlock .embedded-list .embedded-list-element {
  font-size: 1.5rem;
  font-weight: bold;
  padding: 5px;
}

/*
 Software
 --------
*/
.softwarepage {
  background: #4fa9d2;
  height: 50vh;
  width: 100%;
}
.softwarepage .content {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.softwareBlock .contentHolder p {
  color: #d6d4da;
}
.softwareBlock .software-content {
  color: #344150;
}
.softwareBlock .software-card {
  max-height: 300px;
}
.softwareBlock .software-row {
  margin-top: 6%;
}
.software-collapse {
  margin-top: 5%;
  padding: 5px;
}
.softwareBlock .content {
  text-align: center;
}
.softwareBlock .icon {
  font-size: 40px;
  color: #4fa9d2;
  margin: 0 0 10px;
}
.softwareBlock .content {
  color: #30475e;
}
